import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const BlogListLogic = ({ count, loading, setLoading }) => {
  const navigate = useNavigate();

  // 기본 state 관리
  const [state, setState] = useState({
    data_list: [],
  });
  const [paginatedData, setPaginatedData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * count;
  const endIndex = startIndex + count;

  const [bannerList, setBannerList] = useState([]);
  const getList = async (page) => {
    // setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/blogs`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      const json = await response.json();
      // console.log(json);
      // setLoading(false);
      if (json.status === 200) {
        setState({
          ...state,
          data_list: json.data,
          alert: null,
        });
        setCurrentPage(page);
        setPaginatedData(json.data.slice(startIndex, endIndex));
      } else {
        console.log("Error fetching data:", json);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle error
    }
  };
  const getBannerList = async () => {
    // setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/banners`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      const json = await response.json();
      // console.log(json);
      // setLoading(false);
      if (json.status === 200) {
        setBannerList(json.data);
      } else {
        console.log("Error fetching data:", json);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle error
    }
  };

  useEffect(() => {
    localStorage.setItem("prev_page", "/blogs/list");
    getList(currentPage);
    getBannerList();
  }, []);
  useEffect(() => {
    getList(currentPage);
  }, [currentPage]);

  // 데이터 list 관리
  const dataList =
    paginatedData.length > 0 ? (
      paginatedData.map((item, idx) => (
        <li
          className="py-2 px-6 leading-7 border-b border-gray1 cursor-pointer break-all truncate hover:underline hover:decoration-solid hover:font-bold"
          key={`user_${startIndex + idx}`}
          onClick={() =>
            navigate(`/blogs/${item.id}/${item.title}`, {
              state: {
                id: item.id,
                title: item.title,
                contents: item.contents,
              },
            })
          }
        >
          {item.title}
        </li>
      ))
    ) : (
      <li className="py-2 px-6 leading-7 border-b border-gray1 cursor-pointer break-all truncate hover:underline hover:decoration-solid hover:font-bold">
        No blog posts
      </li>
    );

  const bannerDataList =
    bannerList.length > 0
      ? bannerList.map((item, idx) => {
          return (
            <li
              className="h-16 w-full cursor-pointer mt-1 leading-7 cursor-pointer break-all truncate hover:underline hover:decoration-solid hover:font-bold "
              key={`user_${idx}`}
              onClick={() => window.open(item.link_url)}
            >
              <img
                className="object-cover"
                src={item.image_url}
                alt={item.link_url}
              />
            </li>
          );
        })
      : null;
  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };

  return {
    state,
    data: { dataList, bannerDataList, currentPage },
    ref: {},
    func: { paging },
  };
};

export default BlogListLogic;
