import React, { useState, useEffect, useRef } from "react";

//lib
import { scrollTop } from "Utils/lib/helper";

const MainLogic = ({ setLoading, modal, navigate, loading, handleModal }) => {
  // 기본 state 관리
  const ref_instance = {
    id: useRef(null),
    pw: useRef(null),
    pwConfirm: useRef(null),
  };
  const [userInfo, setUserInfo] = useState({
    id: "",
    pw: "",
    pwConfirm: "",
  });
  const [errorMsg, setErrorMsg] = useState(""); // login  Joinus

  const [state, setState] = useState({});
  useEffect(() => {
    const prevPage = localStorage.getItem("prev_page");
    console.log(prevPage);
    if (prevPage !== "/") {
      localStorage.setItem("prev_page", "/");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    setUserInfo({
      id: "",
      pw: "",
      pwConfirm: "",
    });
    setErrorMsg("");
  }, [modal.type]);

  const logIn = async () => {
    if (!userInfo.id) {
      setErrorMsg("Please enter your Nickname");
      return ref_instance.id.current.focus();
    }
    if (!userInfo.pw) {
      setErrorMsg("Please enter your password.");
      return ref_instance.pw.current.focus();
    }
    setLoading(true);
    const json = {
      nickname: userInfo.id,
      password: userInfo.pw,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/signin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          body: JSON.stringify(json),
        }
      );
      const data = await response.json();
      console.log(data);

      if (data.status === 201) {
        localStorage.setItem("user_token", data.data.accessToken);
        localStorage.setItem("user_nickname", data.data.user.nickname);
        localStorage.setItem("user_role", data.data.user.role);

        window.location.href = "/";
      } else {
        setErrorMsg("Check your nickname or password.");
      }
    } catch (error) {
      setErrorMsg(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const joinUs = async () => {
    if (!userInfo.id) {
      setErrorMsg("Please enter your Nickname");
      return ref_instance.id.current.focus();
    }
    if (!userInfo.pw) {
      setErrorMsg("Please enter your password.");
      return ref_instance.pw.current.focus();
    }
    if (!userInfo.pwConfirm) {
      setErrorMsg("Please enter your password confirmation.");
      return ref_instance.pwConfirm.current.focus();
    }
    if (userInfo.pw != userInfo.pwConfirm) {
      setErrorMsg("Password and password confirmation do not match.");
      return ref_instance.pwConfirm.current.focus();
    }
    setLoading(true);
    const json = {
      nickname: userInfo.id,
      password: userInfo.pw,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          body: JSON.stringify(json),
        }
      );
      const data = await response.json();
      console.log(data);

      if (data.status === 201) {
        ref_instance.id.current.value = "";
        ref_instance.pw.current.value = "";
        ref_instance.pwConfirm.current.value = "";
        setUserInfo({
          id: "",
          pw: "",
          pwConfirm: "",
        });
        handleModal("LOGIN");
      } else {
        setErrorMsg(`${data.message}`);
      }
    } catch (error) {
      console.log(error);
      setErrorMsg(`${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  const logOut = async () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return {
    state,
    data: { userInfo, errorMsg },
    ref: { ref_instance },
    func: {
      logIn,
      joinUs,
      setUserInfo,
      logOut,
    },
  };
};

export default MainLogic;
