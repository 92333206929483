import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const BlogLogic = ({ loading, setLoading }) => {
  const navigate = useNavigate();

  // 기본 state 관리
  const [state, setState] = useState({
    data_list: [],
  });

  const [bannerList, setBannerList] = useState([]);
  const getList = async () => {
    // setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/blogs`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      const json = await response.json();
      // console.log(json);
      // setLoading(false);
      if (json.status === 200) {
        setState({
          ...state,
          data_list: json.data,
          alert: null,
        });
      } else {
        console.log("Error fetching data:", json);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle error
    }
  };
  const getBannerList = async () => {
    // setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/banners`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      const json = await response.json();
      // console.log(json);
      // setLoading(false);
      if (json.status === 200) {
        setBannerList(json.data);
      } else {
        console.log("Error fetching data:", json);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle error
    }
  };

  useEffect(() => {
    getList();
    getBannerList();
  }, []);

  // 데이터 list 관리
  const dataList =
    state.data_list.length > 0 ? (
      state.data_list.map((item, idx) => {
        return (
          <li
            className="leading-7 cursor-pointer break-all truncate hover:underline hover:decoration-solid hover:font-bold "
            key={`user_${idx}`}
            onClick={() =>
              navigate(`/blogs/${item.id}/${item.title}`, {
                state: {
                  id: item.id,
                  title: item.title,
                  contents: item.contents,
                },
              })
            }
          >
            {item.title}
          </li>
        );
      })
    ) : (
      <li>No blog posts</li>
    );

  const bannerDataList =
    bannerList.length > 0
      ? bannerList.map((item, idx) => {
          return (
            <li
              className="mx-auto h-16 w-full max-w-md cursor-pointer mt-1 leading-7 cursor-pointer break-all truncate hover:underline hover:decoration-solid hover:font-bold "
              key={`user_${idx}`}
              onClick={() => window.open(item.link_url)}
            >
              <img
                className="object-cover"
                src={item.image_url}
                alt={item.link_url}
              />
            </li>
          );
        })
      : null;

  return {
    state,
    data: { dataList, bannerDataList },
    ref: {},
    func: {},
  };
};

export default BlogLogic;
