// 공통으로 사용되는 스타일 컴포넌트 모음 파일입니다.
import styled from "styled-components";

// Container
export const BasicButton = styled.button`
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  padding: 7px 17px 8px;
  color: var(--text);
  padding: 8px 10px;
  border-radius: 4px;
  transition: background-color 0.1s ease-in-out;
  line-height: 140%;
  box-sizing: border-box;
  letter-spacing: -0.04em;
  &.main {
    padding: 8px 33.5px;
    background-color: var(--main);
    color: var(--white);
  }
  &.main2 {
    padding: 8px 14px;
    background-color: var(--main);
    color: var(--white);
  }
  &.main3 {
    padding: 6px 12px;
    background-color: var(--main);
    color: var(--white);
  }
  &.main_right {
    padding: 8px 14px;
    background-color: var(--main);
    float: right;
    color: var(--white);
  }
  &.add_main {
    margin-left: 10px;
    padding: 8px 14px;
    background-color: var(--main);
    color: var(--white);
  }
  &.grey1 {
    padding: 8px 14px;
    background-color: var(--grey1);
    color: var(--white);
  }
  &.grey3 {
    padding: 6px 12px;
    background-color: var(--grey1);
    color: var(--white);
  }
  &.grey4 {
    width: 81px !important;
    height: 36px;
    background: var(--grey1);
    border-radius: 4px;
    color: var(--white);
  }
  &.white {
    padding: 8px 14px;
    border: 1px solid var(--grey1);
    color: var(--text);
    box-sizing: border-box;
  }
  &.white5 {
    padding: 4px 10px;
    border: 1px solid var(--grey1);
    color: var(--text);
    float: right;
  }
  &.black {
    font-size: 14px;
    padding: 7px 12px;
    background-color: var(--grey1);
    color: var(--white);
    vertical-align: middle;
  }
`;

export const Text = styled.div`
  width: 100%;
  word-break: break-all;
  margin: 20px 0;
  h1 {
    margin: 0;
    padding: 0;
    line-height: 32px;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 28px;
    padding: 0;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 1em;
  }
  img {
    vertical-align: middle;
  }

  a,
  a:link {
    color: #666;
    text-decoration: none;
  }
  a:hover {
    color: #000;
    text-decoration: underline;
  }
  a:active {
    color: #000;
    text-decoration: none;
  }
  h1 {
    font-size: 18px;
    color: #222;
    font-weight: 600;
  }
  h2 {
    font-size: 16px;
    color: #222;
    font-weight: 600;
  }
  h3 {
    font-size: 14px;
    color: #222;
    font-weight: 600;
  }
  h4 {
    font-size: 13px;
    font-weight: 600;
  }
  .picture {
    width: 744px;
    height: 354px;
    background-color: var(--grey5);
    margin-bottom: 14px;
  }
`;

export const GalleryBox = styled.div`
  .my-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .my-gallery .react-grid-gallery-image {
    flex-basis: calc(
      33.333% - 10px
    ); /* 각 이미지의 너비를 33.333%로 설정합니다. */
    margin-bottom: 10px; /* 이미지 간격을 조절합니다. */
  }
`;
