import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

//lib
import { scrollTop } from "Utils/lib/helper";
const MainLogic = ({
  setLoading,
  modal,
  navigate,
  loading,
  handleModal,
  blog_data,
}) => {
  // 기본 state 관리
  const location = useLocation();
  const blogId = parseInt(location.pathname.split("/blogs/")[1].split("/")[0]);

  const ref_instance = {
    id: useRef(null),
    pw: useRef(null),
    pwConfirm: useRef(null),
  };
  const [userInfo, setUserInfo] = useState({
    id: "",
    pw: "",
    pwConfirm: "",
  });
  const [errorMsg, setErrorMsg] = useState(""); // login  Joinus

  const [state, setState] = useState({
    prev: {
      title: null,
      contents: null,
    },
    next: {
      title: null,
      contents: null,
    },
  });

  useEffect(() => {
    localStorage.setItem("prev_page", "/blogs/detail");
    getList();
  }, []);
  useEffect(() => {
    getList();
  }, [blogId]);

  const logIn = async () => {
    if (!userInfo.id) {
      setErrorMsg("닉네임을 입력해주세요.");
      return ref_instance.id.current.focus();
    }
    if (!userInfo.pw) {
      setErrorMsg("비밀번호를 입력해주세요.");
      return ref_instance.pw.current.focus();
    }
    setLoading(true);
    const json = {
      nickname: userInfo.id,
      password: userInfo.pw,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/signin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          body: JSON.stringify(json),
        }
      );
      const data = await response.json();
      console.log(data);

      if (data.status === 201) {
        localStorage.setItem("user_token", data.data.accessToken);
        localStorage.setItem("user_nickname", data.data.user.nickname);
        localStorage.setItem("user_role", data.data.user.role);

        window.location.href = "/";
      } else {
        setErrorMsg("닉네임이나 비밀번호를 확인하세요.");
      }
    } catch (error) {
      setErrorMsg(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const joinUs = async () => {
    if (!userInfo.id) {
      setErrorMsg("Please enter your Nickname");
      return ref_instance.id.current.focus();
    }
    if (!userInfo.pw) {
      setErrorMsg("Please enter your password.");
      return ref_instance.pw.current.focus();
    }
    if (!userInfo.pwConfirm) {
      setErrorMsg("Please enter your password confirmation.");
      return ref_instance.pwConfirm.current.focus();
    }
    if (userInfo.pw != userInfo.pwConfirm) {
      setErrorMsg("Password and password confirmation do not match.");
      return ref_instance.pwConfirm.current.focus();
    }
    setLoading(true);
    const json = {
      nickname: userInfo.id,
      password: userInfo.pw,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          body: JSON.stringify(json),
        }
      );
      const data = await response.json();
      console.log(data);

      if (data.status === 201) {
        ref_instance.id.current.value = "";
        ref_instance.pw.current.value = "";
        ref_instance.pwConfirm.current.value = "";
        setUserInfo({
          id: "",
          pw: "",
          pwConfirm: "",
        });
        handleModal("LOGIN");
      } else {
      }
    } catch (error) {
      setErrorMsg(`${error}`);
    } finally {
      setLoading(false);
    }
  };
  const logOut = async () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const getList = async () => {
    // setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/blogs`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      const json = await response.json();
      // console.log(json);
      // setLoading(false);
      const bloglist = json.data;

      if (json.status === 200) {
        if (blogId && bloglist) {
          const idx = bloglist.findIndex((item) => item.id === blogId);
          console.log(bloglist, bloglist.length, idx, blogId);

          if (idx !== -1) {
            setState({
              ...state,
              id: bloglist[idx].id,
              title: bloglist[idx].title,
              contents: bloglist[idx].contents,
              prev: {
                id: idx === 0 ? null : bloglist[idx - 1].id,
                title: idx === 0 ? null : bloglist[idx - 1].title,
                contents: idx === 0 ? null : bloglist[idx - 1].contents,
              },
              next: {
                id: idx + 1 >= bloglist.length ? null : bloglist[idx + 1].id,
                title:
                  idx + 1 >= bloglist.length ? null : bloglist[idx + 1].title,
                contents:
                  idx + 1 >= bloglist.length
                    ? null
                    : bloglist[idx + 1].contents,
              },
            });
          }
        }
      } else {
        console.log("Error fetching data:", json);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle error
    }
  };

  return {
    state,
    data: { userInfo, errorMsg },
    ref: { ref_instance },
    func: {
      logIn,
      joinUs,
      setUserInfo,
      logOut,
    },
  };
};

export default MainLogic;
