import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
//lib
import { isUrl, scrollTop } from "Utils/lib/helper";
import Block from "Assets/images/icons/btn-block.png";
import { set } from "harmony-reflect";

const user_token = localStorage.getItem("user_token");
const user_role = localStorage.getItem("user_role");
const user_nickname = localStorage.getItem("user_nickname");

// 채팅 소켓
const socket = io("https://api.justtalkup.fun", {
  transports: ["websocket"],
  auth: {
    authorization: `Bearer ${user_token}`,
    ipAddress: "192.168.1.100", // TODO : user의 ip
  },
  // path: "/socket.io",
  secure: true,
});

const ChatLogic = ({ loading, setChatLoading, chatType }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    is_login: user_token ? true : false,
    data_list: [],
    search_keyword: "",
    modal: { open: false, type: "", id: "" },
  });
  const [msg, setMsg] = useState("");
  const [idx, setIdx] = useState(0);

  const [selectedImage, setSelectedImage] = useState(null);
  const [notice, setNotice] = useState({ title: "", contents: "" });
  const [msgPossibleList, setMsgPossibleList] = useState([]);

  const [msgList, setMsgList] = useState([]);
  const [newMsgList, setNewMsgList] = useState([]);
  const [gifList, setGifList] = useState([]);
  const chatInputRef = useRef("");

  const handleSubmitNewMessage = (msg) => {
    console.log("Sending message:", msg);
    console.log("Sending message type:", typeof msg);
    socket.emit("message", msg);
    setStateValue("search_keyword", "");
    chatInputRef.current.value = "";
    setIdx(idx + 1);
    console.log(idx);
  };

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (err) => {
      console.error("WebSocket connection error:", err);
    });

    socket.on("message", (chats) => {
      console.log("Received new messages:", chats);

      setNewMsgList((prevNewMsgList) => {
        const chatInfo = {
          message: chats.message,
          user: {
            nickname: chats.nickname,
          },
        };
        const newMessages = setMessage(chatInfo, idx);
        return [...prevNewMsgList, newMessages];
      });
    });

    socket.on("beforeMessages", ({ chats }) => {
      console.log("Received previous messages:", chats);
      setMsgList((prevMsgList) => {
        const newMessages = chats.map((chat, idx) => setMessage(chat, idx));
        return [...prevMsgList, ...newMessages];
      });
    });
  }, []);

  const setMessage = (chat, idx) => {
    const isMe = chat.user.nickname === user_nickname;
    return (
      <div
        key={`chat_${idx}`}
        className={`chat ${isMe ? "chat-end" : "chat-start"}`}
      >
        <div className="chat-header">
          {isMe ? null : chat.user.nickname}
          {/* ip차단 버튼 */}
          {/* {user_role === "admin" && !isMe ? (
            <img
              src={Block}
              className="inline-block ml-1 h-4 w-4 object-contain"
            />
          ) : null} */}
          {/* <time className="text-xs opacity-50">2 hour ago</time> */}
        </div>
        <div
          className={`chat-bubble ${
            isMe ? "bg-point text-white" : "bg-gray4 text-black"
          }`}
        >
          {isUrl(chat.message) ? (
            <img
              class="w-52 object-contain rounded-2xl"
              src={chat.message}
              alt={chat.message}
            />
          ) : (
            chat.message
          )}
        </div>
      </div>
    );
  };
  // 상단 공지
  const getNotice = async () => {
    setChatLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/notices`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      const json = await response.json();

      console.log(json);
      setChatLoading(false);
      if (json.status === 200) {
        if (json.data.length) {
          setNotice({
            title: json.data[0].title,
            contents: json.data[0].contents,
          });
        }
      } else {
        console.log("Error:", json);
        // alert("error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setChatLoading(false);
      // Handle error
    }
  };
  // 채팅 가능한 메세지 목록
  const getPossibleMsg = async () => {
    setChatLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/possible-messages?message=${state.search_keyword}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      const json = await response.json();

      console.log("getPossibleMsg", state.search_keyword, json);
      setChatLoading(false);
      if (json.status === 200) {
        setMsgPossibleList(json.data);
      } else if (json.status === 401) {
        alert("Please login again.");
        localStorage.clear();
        window.location.href = "/";
      } else {
        console.log("Error:", json);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setChatLoading(false);
      // Handle error
    }
  };
  // gif 목록
  const getGifList = async () => {
    // setChatLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/chats/gifs?limit=10&keyword=${state.search_keyword}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      const json = await response.json();

      console.log("setGifList", state.search_keyword, json);
      if (json.status === 200) {
        // setChatLoading(false);
        // console.log(loadedImages);
        // setGifList(loadedImages);
        setGifList(json.data);
      } else {
        console.log("Error fetching data:", json);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setChatLoading(false);
      // Handle error
    }
  };

  const setIp = async (userIp) => {
    // type : POST / PATCH
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admins/ip-blocks`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
          body: JSON.stringify({
            ip: userIp,
          }),
        }
      );
      const json = await response.json();
      console.log(json);
      if (json.status === 201) {
        alert("사용자를 차단했습니다.");
      } else {
        alert("error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error
    }
  };

  useEffect(() => {
    getNotice();
  }, []);

  useEffect(() => {
    console.log(chatType);
    if (state.search_keyword !== "") {
      if (chatType === "input") {
        getPossibleMsg();
      } else {
        getGifList();
      }
    }
  }, [state.search_keyword, chatType]);

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleSelectImage = (image) => {
    // 선택된 이미지의 정보를 콘솔에 출력합니다.
    console.log("Selected image:", image);
    // 선택된 이미지를 상태에 저장합니다.
    setSelectedImage(image);
    handleSubmitNewMessage(image);
  };

  return {
    state,
    data: {
      notice,
      msgList,
      gifList,
      newMsgList,
      msgList,
      msgPossibleList,
    },
    ref: { chatInputRef },
    func: { setMsg, setStateValue, handleSelectImage, handleSubmitNewMessage },
  };
};

export default ChatLogic;
