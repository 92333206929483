import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Logo from "Assets/images/icons/logo.png";
import { Text } from "Styles/styles";
// logics
import BlogDetailLogic from "./BlogDetail.logic";

const BlogDetail = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [modal, setModal] = useState({ type: "" }); // login  Joinus

  const handleModal = (type) => {
    setModal({ type: type });
    document.getElementById("min-modal").showModal();
  };
  const { state, data, ref, func } = BlogDetailLogic({
    setLoading,
    modal,
    setModal,
    navigate,
    handleModal,
  });

  const user = localStorage.getItem("user_token");

  return (
    <div className="overflow-x-hidden font-f-s">
      <div className="flex x-screen shadow-md z-50 ">
        <div className="w-screen hidden py-6 px-24  bg-white md:flex">
          <div
            className=" flex-1 cursor-pointer flex items-center "
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              className="w-60 object-contain inline-block"
              src={Logo}
              alt="Just talk up Logo"
            />
            <div className="inline-block text-3xl font-bold ">
              {" "}
              <span className="font-light text-gray1 mx-3 ">|</span>Blog

            </div>
          </div>
          {user ? (
            <div
              onClick={() => func.logOut()}
              className=" cursor-pointer flex-none flex font-semibold justify-center items-center margin-i-0 "
            >
              LOGOUT
            </div>
          ) : (
            <ul className="flex-none flex  justify-center items-center  margin-i-0  ">
              <li
                onClick={() => handleModal("LOGIN")}
                className="mr-10 font-semibold cursor-pointer margin-b-i-0"
              >
                LOGIN
              </li>
              <li
                onClick={() => handleModal("JOIN US")}
                className="font-semibold cursor-pointer margin-b-i-0"
              >
                JOIN US
              </li>
            </ul>
          )}
        </div>
        <div className="w-screen md:hidden ">
          <div
            className="w-full  py-6 px-20 cursor-pointer pb-100 "
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              className="w-60 object-contain mr-auto ml-auto"
              src={Logo}
              alt="Just talk up Logo"
            />
          </div>
          <div className="bg-point text-white py-2 ">
            {user ? (
              <div className="flex-none flex  justify-center items-center  ">
                <div className="cursor-pointer font-light">
                  Hello,
                  <span className="font-semibold">
                    {localStorage.getItem("user_nickname")}
                  </span>
                </div>
                <span className="mx-3">|</span>{" "}
                <div
                  onClick={() => func.logOut()}
                  className="cursor-pointer font-semibold margin-i-0"
                >
                  LOGOUT
                </div>
              </div>
            ) : (
              <ul className="flex-none flex  justify-center items-center margin-i-0 pl-0">
                <li
                  onClick={() => handleModal("LOGIN")}
                  className="font-semibold cursor-pointer  margin-b-i-0"
                >
                  LOGIN
                </li>
                <span className="mx-3">|</span>
                <li
                  onClick={() => handleModal("JOIN US")}
                  className="font-semibold cursor-pointer  margin-b-i-0"
                >
                  JOIN US
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="md:w-3/5 md:mx-auto min-h-screen bg-white border border-gray1 py-20 md:px-16 px-10 h-full">
        <div className="bold text-3xl pb-8 border-b border-gray1">
          {state.title}
        </div>
        <Text>
          <div
            className="pb-8 border-b border-gray1"
            dangerouslySetInnerHTML={{
              __html: state.contents,
            }}
          ></div>
        </Text>
        <div className="flex">
          <button
            className={`join-item btn ${state.prev.id ? "" : "opacity-0"}`}
            onClick={() =>
              navigate(`/blogs/${state.prev.id}/${state.prev.title}`, {
                state: {
                  id: state.prev.id,
                  title: state.prev.title,
                  contents: state.prev.contents,
                },
              })
            }
          >
            « Prev
          </button>
          <div className="grow"></div>
          <button
            className={`join-item btn ${state.next.id ? "" : "opacity-0"}`}
            onClick={() =>
              navigate(`/blogs/${state.next.id}/${state.next.title}`, {
                state: {
                  id: state.next.id,
                  title: state.next.title,
                  contents: state.next.contents,
                },
              })
            }
          >
            Next »
          </button>
        </div>
        <div className="flex flex-row-reverse mt-3">
          <button
            className={`join-item btn ${state.next.id ? "" : "opacity-0"}`}
            onClick={() => navigate(`/`)}
          >
            Free Fun Chat »
          </button>
        </div>
      </div>
      <div className="bg-gray1 py-4 w-screen">
        <div className="flex  h-full items-center mx-auto w-9/12">
          <div className="grow">2024 © JUST TALK UP. All rights reserved.</div>
          <div className=" flex gap-3">
            {/* /blogs/게시물_아이디/게시물_제목 */}
            <p onClick={() => navigate(`/blogs/4/Policy&Terms`)}>POLICY</p>
            <p onClick={() => navigate(`/blogs/5/F&Qs`)}>FAQ</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
