import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Chat from "Views/Chat/Chat";
// import Chat from "Views/Chat/Chat-new";

import Logo from "Assets/images/icons/logo.png";
import Twitter from "Assets/images/icons/logo-twitter.png";
import Insta from "Assets/images/icons/logo-insta.png";
import Blog from "Views/Blog/Blog";

// logics
import MainLogic from "./Main.logic";

const Main = ({ loading, setLoading }) => {
  const navigate = useNavigate();

  const [modal, setModal] = useState({ type: "" }); // login  Joinus

  const handleModal = (type) => {
    setModal({ type: type });
    document.getElementById("min-modal").showModal();
  };
  const { state, data, ref, func } = MainLogic({
    setLoading,
    modal,
    setModal,
    navigate,
    handleModal,
  });

  const user = localStorage.getItem("user_token");

  return (
    <div className="flex flex-col h-screen w-screen overflow-x-hidden">
      <div className="flex x-screen shadow-md z-50 ">
        <div className="w-screen hidden py-6 px-24  bg-white md:flex">
          <div
            className=" flex-1 cursor-pointer "
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              className="w-60 object-contain"
              src={Logo}
              alt="Just talk up Logo"
            />
          </div>
          {user ? (
            <div
              onClick={() => func.logOut()}
              className="text-base cursor-pointer flex-none flex font-semibold justify-center items-center font-semibold "
            >
              LOGOUT
            </div>
          ) : (
            <ul className="flex-none flex  justify-center items-center ">
              <li
                onClick={() => handleModal("LOGIN")}
                className="mr-10 font-semibold cursor-pointer"
              >
                LOGIN
              </li>
              <li
                onClick={() => handleModal("JOIN US")}
                className="font-semibold cursor-pointer"
              >
                JOIN US
              </li>
            </ul>
          )}
        </div>
        <div className="w-screen md:hidden">
          <div
            className="w-full  py-6 px-20 cursor-pointer pb-100 "
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              className="w-60 object-contain mr-auto ml-auto"
              src={Logo}
              alt="Just talk up Logo"
            />
          </div>
          <div className="bg-point text-white py-2">
            {user ? (
              <div className="flex-none flex  justify-center items-center ">
                <div className="cursor-pointer font-light">
                  Hello,
                  <span className="font-semibold">
                    {localStorage.getItem("user_nickname")}
                  </span>
                </div>
                <span className="mx-3">|</span>{" "}
                <div
                  onClick={() => func.logOut()}
                  className="cursor-pointer font-semibold "
                >
                  LOGOUT
                </div>
              </div>
            ) : (
              <ul className="flex-none flex  justify-center items-center ">
                <li
                  onClick={() => handleModal("LOGIN")}
                  className="font-semibold cursor-pointer"
                >
                  LOGIN
                </li>
                <span className="mx-3">|</span>
                <li
                  onClick={() => handleModal("JOIN US")}
                  className="font-semibold cursor-pointer"
                >
                  JOIN US
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      {modal.open ? <></> : null}
      <ul className="grow flex-auto flex md:flex-row  flex-col">
        <li className="basis-2/3">
          <Chat loading={loading} setLoading={setLoading} />
        </li>
        <li className="w-4/12">
          <Blog loading={loading} setLoading={setLoading} />
        </li>
      </ul>
      <div className="bg-gray1 py-4 w-screen">
        <div className="hidden md:flex h-full items-center mx-auto w-9/12">
          <div className="grow text-gray3">
            <div>
              contact us: <a href="mailto:j2talk@proton.me">j2talk@proton.me</a>
            </div>
            <div>2024 © JUST TALK UP. All rights reserved.</div>
          </div>
          <div className="flex gap-3 mr-8">
            <img
              className="h-7 object-contain"
              onClick={() => window.open("https://www.instagram.com/")}
              src={Insta}
              alt="Just talk up Insta"
            />
            <img
              className="h-7 object-contain"
              src={Twitter}
              onClick={() => window.open("https://x.com/")}
              alt="Just talk up Twitter"
            />
          </div>
          <div className=" flex gap-3">
            {/* /blogs/게시물_아이디/게시물_제목 */}
            <p
              onClick={() =>
                navigate(
                  `/blogs/7/What%20is%20Just%20Talk%20Up?%20About%20us,%20Policy&Terms`
                )
              }
            >
              POLICY
            </p>
            <p onClick={() => navigate(`/blogs/5/F&Qs`)}>FAQ</p>
          </div>
        </div>
        <div className="md:hidden bg-gray1 h-full items-center mx-auto w-9/12 text-center">
          <div className=" text-gray3">
            <div>
              contact us: <a href="mailto:j2talk@proton.me">j2talk@proton.me</a>
            </div>
            <div>2024 © JUST TALK UP. All rights reserved.</div>
          </div>
          <div className=" my-2">
            {/* /blogs/게시물_아이디/게시물_제목 */}
            <p
              className="inline-block mr-2 bg-point p-1 text-white"
              onClick={() =>
                navigate(
                  `/blogs/7/What%20is%20Just%20Talk%20Up?%20About%20us,%20Policy&Terms`
                )
              }
            >
              POLICY
            </p>
            <p
              className="inline-block ml-2 bg-point p-1 text-white"
              onClick={() => navigate(`/blogs/5/F&Qs`)}
            >
              FAQ
            </p>
          </div>
          <div className="">
            <img
              className="inline-block mr-2  w-7 h-7 object-contain"
              src={Insta}
              onClick={() => window.open("https://www.instagram.com/")}
              alt="Just talk up Insta"
            />
            <img
              className="inline-block ml-2 w-7 h-7 object-contain"
              src={Twitter}
              onClick={() => window.open("https://x.com/")}
              alt="Just talk up Twitter"
            />
          </div>
        </div>
      </div>
      {/* modal */}
      <dialog id="min-modal" className="modal">
        <div className="modal-box md:w-2/5">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <div className="flexs">
            <img
              className="w-60 object-contain mb-6"
              src={Logo}
              alt="Just talk up Logo"
            />
            <h3 className="font-bold text-xl">{modal.type}</h3>
          </div>
          <label className="input input-bordered flex items-center gap-2 my-3">
            <span className="w-40">NickName</span>
            <input
              type="text"
              className="grow"
              placeholder="Nickname"
              ref={ref.ref_instance.id}
              value={data.userInfo.id}
              onChange={({ target: { value: id } }) =>
                func.setUserInfo({ ...data.userInfo, id })
              }
            />
          </label>
          <label className="input input-bordered flex items-center gap-2 my-3">
            <span className="w-40">Password</span>
            <input
              type="password"
              className="grow"
              placeholder="Password"
              ref={ref.ref_instance.pw}
              value={data.userInfo.pw}
              onChange={({ target: { value: pw } }) =>
                func.setUserInfo({ ...data.userInfo, pw })
              }
            />
          </label>
          {modal.type === "JOIN US" ? (
            <label className="input input-bordered flex items-center gap-2 my-3 break-keep">
              <span className="w-40">Password Confirm</span>
              <input
                type="password"
                className="grow"
                placeholder="Password Confirm"
                ref={ref.ref_instance.pwConfirm}
                onChange={({ target: { value: pwConfirm } }) =>
                  func.setUserInfo({ ...data.userInfo, pwConfirm })
                }
              />
            </label>
          ) : null}
          <p className="text-point my-3">{data.errorMsg}</p>
          <button
            onClick={
              modal.type === "LOGIN" ? () => func.logIn() : () => func.joinUs()
            }
            className="btn btn-block bg-point text-white"
          >
            {modal.type}
          </button>
        </div>

        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
};

export default Main;
