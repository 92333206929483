import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// logics
import BlogLogic from "./Blog.logic";

const Blog = ({ setLoading }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState({ open: false, type: "", id: "" });
  const { state, data, ref, func } = BlogLogic({
    setLoading,
    modal,
    setModal,
  });

  return (
    <div className="py-16 px-12 h-full flex flex-col md:bg-gray1 bg-grayBg w-screen sm:w-full sm:bg-white">
      <div className="flex">
        <h2 className="grow text-3xl font-semibold">Blog</h2>
        <button className="text-point ">
          <span
            className="align-middle hover:underline hover:decoration-solid hover:font-bold"
            onClick={() => navigate(`/blogs/list`)}
          >
            more
          </span>
          <i className="xi-angle-right-min text-point align-middle h-5 mt-px" />
        </button>
      </div>
      <ul className="grow mt-3 w-full h-full overflow-y-auto">
        {data.dataList}
      </ul>
      <ul className="mt-3">{data.bannerDataList}</ul>

      {/* <Text>
        <div
        dangerouslySetInnerHTML={{
          __html: "<div>sdfgsdfg</div>",
        }}
      ></div>
      </Text> */}
    </div>
  );
};

export default Blog;
