// 기본 적용 스타일 코드입니다.
import { createGlobalStyle } from "styled-components";
// icon
import arrowDown from "Assets/images/icons/arrow_down_black.png";

// 사용되는 폰트입니다.
import SpoqaHanSansNeoBold from "Assets/fonts/SpoqaHanSansNeo-Bold.ttf";
import SpoqaHanSansNeoMedium from "Assets/fonts/SpoqaHanSansNeo-Medium.ttf";
import SpoqaHanSansNeoRegular from "Assets/fonts/SpoqaHanSansNeo-Regular.ttf";
import SpoqaHanSansNeoLight from "Assets/fonts/SpoqaHanSansNeo-Light.ttf";
import SpoqaHanSansNeoThin from "Assets/fonts/SpoqaHanSansNeo-Thin.ttf";

const globalStyle = createGlobalStyle`
   :root {
      --main: #fd9377;
      --text: #2E2E2E;
      --black: #000000;
      --grey1: #3B3B3B;
      --grey2: #7D7D7D;
      --grey3:#bababa;
      --grey4: #E4E4E4;
      --grey5:#f6f6f6;
      --grey6:#FCFCFC;
      --white: #ffffff;
      --red: #F9665C ;
   }

   /* SpoqaHanSansNeo font */
   @font-face {
     font-family: 'Spoqa Han Sans Neo', 'sans-serif';
      src: url(${SpoqaHanSansNeoBold}) format('opentype');
      font-weight: 600;
      font-style: bold;
   }
   @font-face {
     font-family: 'Spoqa Han Sans Neo', 'sans-serif';
      src: url(${SpoqaHanSansNeoMedium}) format('opentype');
      font-weight: 500;
      font-style: normal;
   }
   @font-face {
      font-family: 'Spoqa Han Sans Neo', 'sans-serif';
       src: url(${SpoqaHanSansNeoRegular}) format('opentype');
       font-weight: 400;
       font-style: normal;
    }
   @font-face {
     font-family: 'Spoqa Han Sans Neo', 'sans-serif';
      src: url(${SpoqaHanSansNeoLight}) format('opentype');
      font-weight: 300;
      font-style: normal;
   }
   @font-face {
     font-family: 'Spoqa Han Sans Neo', 'sans-serif';
      src: url(${SpoqaHanSansNeoThin}) format('opentype');
      font-weight: 200;
      font-style: normal;
   }

   * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      line-height: 1;
   }
   html {
      font-size: 16px;
      color: #333333;
   }
   body {
      font-family: 'Spoqa Han Sans Neo', sans-serif;
      -webkit-touch-callout: none;
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      letter-spacing: -0.04em;
      background-color: #f9f9f9;
      margin: 0!important;
      font-size: 16px!important;
   }
   #root {
      position: relative;
      width: 100%;
      /* height: 100vh; */
      background: var(--background);
      /* overflow: hidden; */
   }
   // 기본 셋팅 부분
   a {
      text-decoration: none;
      color: inherit;
   }
   img {
      width: 100%;
   }
   ul, ol {
      list-style-type: none;
   }
   input,
   select,
   textarea {
      font-family: inherit;
      color: inherit;
      font-size: inherit;
      outline: none;
      resize: none;
      background-color: transparent;
      border-color:transparent;
      &::placeholder {
         color:#bababa;
      }
   }
  
   input[type="number"] {
      -moz-appearance: textfield;
   }
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      :checked ~ .check {
         background-color: var(--main);
         &::after {
            display: block;
         }
      }
   }
   select {
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      border: 1px solid #e0e0e0;
      width: 300px;
      height: 38px;
      padding: 0 5px;
      background: url(${arrowDown}) calc(100% - 5px) center no-repeat;
      background-size: 20px;
   
   }
   // 기본 버튼 설정
   button {
      font-family: inherit;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      /* &:active,&:hover{
         opacity: 0.8;
      }        */

   }
   i {
      color: var(--black);
      }
   // 글씨 크기
   h1{
      font-size: 28px;
   }
   h2{
      font-size: 20px;
   }
   h3{
      font-size: 16px;
   }
   h4{
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
   }
   h5{
      font-size: 12px;
   }
   p{
      font-size: 14px;
   }
   input{
      width: 100%;
      display: block;
   }
   textarea {
      line-height: 140%;
   }
   .main_section {
      height: calc(100vh - 140px);
   }
   .chat_list {
      height: calc(100vh - 251px);
   }
   
`;

export default globalStyle;
