import React from "react";
import GlobalStyle from "Styles/global.js";
import MainApp from "./MainApp";

const App = () => {
  return (
    <>
      <GlobalStyle />
      <MainApp />
    </>
  );
};

export default App;
