import styled from "styled-components";

export const View = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  z-index: 999;
`;

export const Main = styled.div`
  padding: 20px;
  width: ${({ modalWidth }) => (modalWidth ? modalWidth : 300) + "px"};
  border-radius: ${({ modalBorderRadius }) =>
    (modalBorderRadius ? modalBorderRadius : 0) + "10px"};
  /* max-height: 720px; */
  /* max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 852) + "px"}; */
  background: #fff;
  overflow: hidden;
  position: relative;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0.6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
  .line {
    width: 100%;
    height: 1px;
    border: 1px solid var(--grey4);
    display: block;
    margin: 14px 0;
  }
  p {
    text-align: left;
    color: var(--text);
    font-size: 16px;
  }
  .agree_text {
    text-align: left;
    p {
      margin-bottom: 30px;
      line-height: 140%;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .scrollBox {
    overflow-y: auto;
    height: 495px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #7f7f7f;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0.6;
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: #f3f3f3;
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
    li {
      width: 100%;
      height: 46px;
      display: inline-block;
      position: relative;
      text-align: left;
      margin-bottom: 10px;
      img {
        width: 46px;
        height: 46px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
        object-fit: cover;
      }
      span {
        display: inline-block;
        width: 514px;
        text-align: left;
        vertical-align: middle;
        h6 {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: var(--text);
        }
        p {
          font-size: 12px;
          color: var(--grey2);
          line-height: 140%;
        }
      }
      .pick {
        padding: 5.5px 10px;
        background-color: var(--main);
        color: var(--white);
        font-size: 12px;
        border: none;
        width: 45px;
        height: 28px;
        line-height: 140%;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        right: 0;
        margin-right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .btns {
    width: 100%;
    text-align: right;
    margin-top: 20px;
    button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    &.right {
      text-align: right;
    }
    &.left {
      text-align: left;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 700;
    color: var(--text);
    text-align: left;
    margin-bottom: 12px;
    display: block;
    line-height: 140%;
    /* width: calc(100% - 40px); */
    width: 100%;
    word-break: keep-all;
  }
  h5 {
    font-size: 16px;
    font-weight: 400;
    color: var(--text);
    text-align: left;
    display: block;
    line-height: 140%;
  }
  .closebtn {
    position: absolute;
    right: 15px;
    top: 20px;
    border: none;
  }
  i {
    color: #000;
    font-size: 16px;
  }
  > div {
    width: 100%;
    input {
      width: 100%;
    }
    &.btn_section {
      text-align: right;
      position: relative;
      min-height: 32px;
      button {
        margin-left: 10px;
        &.red {
          position: absolute;
          left: 0;
          margin-left: 0;
        }
        &.cancel_btn {
          position: absolute;
          right: 0;
        }
        &.gray {
          position: absolute;
          left: 57px;
        }
      }
    }
  }
`;
