import React, { useEffect, useState } from "react";

import View from "./PagerStyle";

const Pager = ({ page, total, paging, count }) => {
  const pagesPerBlock = 10;
  const [totalPage, setTotalPage] = useState(0);
  const [pageGroup, setPageGroup] = useState(0);
  const [last, setLast] = useState(0);
  const [first, setFirst] = useState(0);

  const setGroupInfo = () => {
    let tp = Math.ceil(total / count);
    if (tp !== totalPage) setTotalPage(tp);

    let pg = Math.ceil(page / pagesPerBlock);
    if (pg !== pageGroup) setPageGroup(pg);
  };

  useEffect(setGroupInfo, [total, count, page, setGroupInfo]);

  const setRangeInfo = () => {
    let _last = pageGroup * pagesPerBlock;
    if (_last > totalPage) _last = totalPage;
    if (_last !== last) setLast(_last);

    let _first = 1 + (pageGroup - 1) * pagesPerBlock;
    if (_first !== first) setFirst(_first);
  };

  useEffect(setRangeInfo, [pageGroup, totalPage, setRangeInfo]);

  return (
    <View>
      {total ? (
        <>
          {total > 100 && first > 1 && (
            <button onClick={paging} value={first - 1}></button>
          )}
          <ol>
            {[...Array(pagesPerBlock)].map((num, index) => {
              num = first + index;
              return num > totalPage ? null : (
                <li
                  key={num}
                  value={num}
                  onClick={paging}
                  className={parseInt(page) === num ? "active" : ""}
                >
                  {num}
                </li>
              );
            })}
          </ol>
          {total > 100 && last < totalPage && (
            <button onClick={paging} value={last + 1}></button>
          )}
        </>
      ) : null}
    </View>
  );
};

export default Pager;
