import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Loading, SubNav, MainNav } from "./Components";

import { Main, BlogDetail, BlogList } from "Views";

const MainApp = () => {
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;
  const [fakeLoading, setFakeLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      // 로딩이 0.5초 이상 걸릴때만 로딩화면 띄움
      setTimeout(() => {
        if (loadingRef.current) setFakeLoading(true);
      }, 500);
    } else setFakeLoading(false);
  }, [loading]);

  return (
    <BrowserRouter>
      <div className="view">
        {fakeLoading && <Loading loading={fakeLoading} />}
        <Routes>
          <Route
            path="/"
            element={<Main loading={loading} setLoading={setLoading} />}
          />
          <Route
            path="/blogs/list"
            element={<BlogList loading={loading} setLoading={setLoading} />}
          />
          <Route
            path="/blogs/:id/:title"
            element={<BlogDetail loading={loading} setLoading={setLoading} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default MainApp;
