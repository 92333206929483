import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Notice from "Assets/images/icons/icon-notice.png";
import Block from "Assets/images/icons/btn-block.png";
import { Gallery } from "react-grid-gallery";

// logics
import ChatLogic from "./Chat.logic";
import { GalleryBox } from "Styles/styles";
import { Loading } from "Components";

const Chat = ({ setLoading }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState({ open: false, type: "", id: "" });
  const [gifList, onGifList] = useState(false);
  const [chatType, setChatType] = useState("input"); // input , gif
  const [chatLoading, setChatLoading] = useState(false);

  const gifListRef = useRef(null); // Reference for the ul element
  const chatListRef = useRef(null);

  const handleClickOutside = (event) => {
    if (gifListRef.current && !gifListRef.current.contains(event.target)) {
      onGifList(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { state, data, ref, func } = ChatLogic({
    setLoading,
    modal,
    setModal,
    navigate,
    chatType,
    setChatLoading,
  });

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [data.msgList, data.newMsgList]);

  return (
    <div className="bg-grayBg h-full w-full ">
      <div className="h-full  flex flex-col md:ml-auto md:w-9/12 bg-white relative  m-auto w-full">
        {chatLoading && <Loading loading={chatLoading} />}
        {data.notice.title == "" && data.notice.contents == "" ? null : (
          <div className="w-full  p-5 border-b border-gray1 flex bg-gray0">
            <img
              className="w-5 object-contain justify-center items-center  mr-2 "
              src={Notice}
              alt="Notice"
            />
            <div className=" text-base items-center inline-block justify-center items-center break-all glow">
              [{data.notice.title}] {data.notice.contents}
            </div>
          </div>
        )}
        <div
          className="grow bg-white p-2.5 overflow-y-auto chat_list"
          ref={chatListRef}
        >
          <div>{data.msgList}</div>
          <div>{data.newMsgList}</div>
        </div>
        {/* 채팅 창 입력 부분 : gif , msfList */}
        <div ref={gifListRef}>
          {state.search_keyword !== "" ? (
            chatType === "gif" ? (
              data.gifList ? (
                data.gifList.length === 0 ? (
                  <div className="px-4 pt-3 border-t border-gray2">
                    No search results
                  </div>
                ) : (
                  <div className=" border-t border-gray2 pt-4">
                    <div className="grid grid-cols-3 gap-1 h-fit overflow-y-auto max-h-60 ">
                      {data.gifList.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => func.handleSelectImage(item.original)}
                          className="cursor-pointer overflow-hidden"
                        >
                          <img
                            className="object-cover h-full hover:scale-110 ease-in"
                            src={item.downsized}
                            alt={`${state.search_keyword} image`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )
              ) : (
                <div>null</div>
              )
            ) : data.msgPossibleList ? (
              data.msgPossibleList.length === 0 ? (
                <div className="px-4 pt-3 border-t border-gray2">
                  No search results
                </div>
              ) : (
                <ul className="flex flex-wrap gap-3 px-2 py-3 h-fit max-h-20 overflow-y-auto border-t border-gray2">
                  {data.msgPossibleList.map((item) => (
                    <li
                      key={`msg_${item.id}`}
                      className="btn btn-xs bg-grayBg py-2 px-4 rounded-full flex-none h-fit"
                      onClick={() => func.handleSubmitNewMessage(item.message)}
                    >
                      {item.message}
                    </li>
                  ))}
                </ul>
              )
            ) : null
          ) : // <div className="px-4 pt-3 border-t border-gray2">Please enter</div>
          null}

          <div className="m-7 bg-white  relative">
            <input
              ref={ref.chatInputRef}
              type="text"
              placeholder={state.is_login ? "Message" : "Please log in."}
              className="text-lg input input-bordered rounded-full w-full w-full "
              disabled={!state.is_login}
              onChange={({ target: { value } }) =>
                func.setStateValue("search_keyword", value)
              }
            />
            <div className="absolute top-0 end-4 h-12 flex font-semibold justify-center items-center ">
              <li
                onClick={() =>
                  setChatType(chatType === "gif" ? "input" : "gif")
                }
                className={`${
                  chatType === "gif"
                    ? "cursor-pointer border border-2 border-gray3 rounded-md py-[5px] px-[3px]  inline-block text-gray3"
                    : "cursor-pointer py-[5px] px-[3px]  inline-block text-gray3 "
                }
                `}
              >
                GIF
              </li>
              {/* <li className="cursor-pointer inline-block text-point">SEND</li> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
