// selector의 스크롤 상단 이동
export function scrollTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

// 폼데이터 생성
export const setFormData = (data) => {
  const form = new FormData();
  for (let v in data) {
    form.append(v, data[v]);
  }
  return form;
};

// file 객체와 image 선택 시 실행할 함수 입력 시 -> 이미지 업로드 되면 함수 실행
export const setImageFromFile = ({ file, setImageUrl }) => {
  let reader = new FileReader();
  reader.onload = function () {
    setImageUrl({ result: reader.result });
  };
  reader.readAsDataURL(file);
};

// 데이터 값 검증
export const getDataType = (item) => {
  var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
  if (item !== null) {
    if (Array.isArray(item)) {
      const arr = item.map((i, idx) => <span key={`item_${idx}`}>{i}</span>);
      return arr;
    } else {
      if (item.match(reg) !== null) {
        return <img src={item} alt="" />;
      } else {
        return item;
      }
    }
  } else {
    return "";
  }
};

// form data 확인
export const isAllDataIn = (arr) => {
  let needValues = [];
  for (var key in arr) {
    if (arr[key] === "" || arr[key] === null || arr[key] === []) {
      needValues.push(key);
    }
  }
  return needValues;
};

// 용량체크
export const checkFileSize = (maxFileSizeMb, size) => {
  const max = maxFileSizeMb * 1024 * 1024;
  if (size > max) {
    // alert(`용량이 ${maxFileSizeMb}MB 이하인 이미지만 업로드 가능합니다.`);
    return false;
  }
  return true;
};

// html 안에서 이미지 파일 찾기
export const checkImageFileInHtml = (content) => {
  const httpsImageSrcs = [];
  const regex = /<img[^>]*src='(https:\/\/[^']*)'[^>]*>/g;

  let match;
  while ((match = regex.exec(content)) !== null) {
    httpsImageSrcs.push(match[1]);
  }
  if (httpsImageSrcs) return httpsImageSrcs;
};

export const isUrl = (str) => {
  // URL 형식의 정규 표현식
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  // 주어진 문자열이 URL 형식인지 확인
  return urlRegex.test(str);
};
